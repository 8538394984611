export const response = {
  refreshToken: 'HNwSpuCpM-uN42Sf5eczslZ60zraLP9khw1ZMAKqR0zjnHKr20E5Xw',
  token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlVVdzVZdVFWNnd2R29PdmZYNHBxWTdwS18zbyJ9.eyJhdWQiOiJmMGRkYjNmNi0wOTFiLTQ1ZTQtOGMwZi04ODlmODlkNGY1ZGEiLCJleHAiOjE2NTcwNjU1NjUsImlhdCI6MTY1MzQ2NTU2NSwiaXNzIjoiYWNtZS5jb20iLCJzdWIiOiI2ZmU0N2RhNi1iNTM4LTRmN2UtYjI1Zi0xMTFiNWNmYWFkNWUiLCJqdGkiOiJiNTVkNmU5NS01Njc1LTRhMjUtYjY5OC1hYmM4Y2I2OGM3NzIiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoicHJhZ3lhQHNhbWFncmFnb3Zlcm5hbmNlLmluIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInByZWZlcnJlZF91c2VybmFtZSI6IjExMCIsImFwcGxpY2F0aW9uSWQiOiJmMGRkYjNmNi0wOTFiLTQ1ZTQtOGMwZi04ODlmODlkNGY1ZGEiLCJyb2xlcyI6WyJzY2hvb2wiXSwidXNlckRhdGEiOnsiRkNNLnRva2VuIjoiIiwiYWNjb3VudE5hbWUiOiJTYWxvbmkgQ2hhdWRocnkiLCJmMGRkYjNmNi0wOTFiLTQ1ZTQtOGMwZi04ODlmODlkNGY1ZGEiOnsicm9sZUlkIjoiNWVlOWQ3ZTZlNjgwNmU3NmE4NTUxOTQ4In0sImpvaW5pbmdEYXRlIjoiREQvTU0vWVlZWSIsInBob25lIjoiOTg3MTg2OTMyNCIsInNjaG9vbCI6MTI5OCwidWRpc2UiOiIxMTAifSwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInNjaG9vbCIsInRlYWNoZXIiLCJBZG1pbiJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzY2hvb2wiLCJYLUhhc3VyYS1Vc2VyLUlkIjoiMTEwIiwiWC1IYXN1cmEtTG9naW4tSWQiOiI2ZmU0N2RhNi1iNTM4LTRmN2UtYjI1Zi0xMTFiNWNmYWFkNWUiLCJYLUhhc3VyYS1TY2hvb2wtSWQiOiIxMjk4IiwiWC1IYXN1cmEtVWRpc2UtSWQiOiIxMTAifSwicG9saWN5IjoiZXNhbXdhZCJ9.KnVCRMtwnous9ABNGq1Ig_5BVxLeLFdOGRfR_bpEBqHWIjvYOo_yXMgF2Au36Jkgk6btYIsNYrFC1NgLtleWkltiCUUaxwAMIDV7PSph7Nw3COk0SafR55hRuuhnQYJQQFMzgb6Iuw7ie8FPHifMlQGuOv2YvmwKRnTYFXw1CU3Z3Sdpv6pAHJVHDzIeCEOBVSYXgC0UC13tH6D0MRvI4wxjK8oW4N7s6RCyGZbbDNzbKu526k9_BUQ1P9R8MOn0MuQItEfR0UNYJ10Q_OhpA37U45H_mQIy7SjaZc_roZjtjPLerJn-iR9l4eMTMfbY1sFl01koKDkH6PVRic8TTw',
  user: {
    active: true,
    connectorId: 'e3306678-a53a-4964-9040-1c96f36dda72',
    data: {
      'FCM.token': '',
      accountName: 'Saloni Chaudhry',
      'f0ddb3f6-091b-45e4-8c0f-889f89d4f5da': {
        roleId: '5ee9d7e6e6806e76a8551948',
      },
      joiningDate: 'DD/MM/YYYY',
      phone: '9871869324',
      school: 1298,
      udise: '110',
    },
    email: 'pragya@samagragovernance.in',
    fullName: '110',
    id: '6fe47da6-b538-4f7e-b25f-111b5cfaad5e',
    insertInstant: 1561972630448,
    lastLoginInstant: 1653465565729,
    lastUpdateInstant: 1648474974405,
    memberships: [
      {
        groupId: 'fa85bc43-fc8a-48d0-ace1-f83862d90f63',
        id: '30e1a617-1e4e-4ef5-94a5-421939a675d8',
        insertInstant: 1603454912255,
      },
    ],
    mobilePhone: '9871869324',
    passwordChangeRequired: false,
    passwordLastUpdateInstant: 1627874718193,
    registrations: [
      {
        applicationId: 'f0ddb3f6-091b-45e4-8c0f-889f89d4f5da',
        id: 'dfce9c96-6ace-4a15-b204-ec1f42370276',
        insertInstant: 1561972636915,
        lastLoginInstant: 1653465565729,
        lastUpdateInstant: 1629955942836,
        roles: [
          'school',
        ],
        username: '110',
        usernameStatus: 'ACTIVE',
        verified: true,
      },
    ],
    tenantId: '064458d0-dd05-e293-3709-a06cc6ca5ed7',
    twoFactorDelivery: 'None',
    twoFactorEnabled: false,
    username: '110',
    usernameStatus: 'ACTIVE',
    verified: true,
  },
};
