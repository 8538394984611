import React, { PureComponent } from 'react';

class NotFound extends PureComponent {
  render() {
    return (
      <section>
        <div className="text-center mt-5 text-teal-700">Page Not Found</div>
      </section>
    );
  }
}

export default NotFound;
